import React, {useEffect, useState} from 'react';
import axios from "axios/index";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import AddCard from "./AddCard";

const stripePromise = loadStripe(`${process.env.STRIPE_PRODUCTION_KEY}`);

const AttachedCard = () => {
    const [carddetail, setCarddetail] = useState({})
    const [defaultcard, setDefaultcard] = useState({})
    const [cardhide, setCardhide] = useState(true)
    useEffect(() => {
        fetchData();
    }, []);

    const isBrowser = typeof window !== "undefined";
    const loggedIn = isBrowser ? (localStorage.getItem('aruzyuser') !== null) : false;
    const aruzyuser = isBrowser ? localStorage.getItem('aruzyuser') : {};
    const aruzyuser1 = isBrowser && window.localStorage.getItem('aruzyuser') !== null ? JSON.parse(aruzyuser) : {};


    const fetchData = () => {
        axios.post(`${process.env.API_URL}/getcard`, {
            'email': aruzyuser1.email,
        }).then((res) => {
            setCarddetail(res.data.data.data);
            setDefaultcard(res.data.currentcustomer);
            setCardhide(false);
            document.getElementsByClassName('removechecked')[0].checked = true;
        }).catch((error) => {
            console.log(error);
        });
    };

    function getMonth(month) {
        const date = new Date(2009, month, 10);  // 2009-11-10
        return date.toLocaleString('default', {month: 'long'});
    }

    const setDefault = (cardid) => {
        if (window.confirm("Are you sure want to make this card default.")) {
            document.getElementsByClassName('removechecked')[0].checked = false;
            axios.post(`${process.env.API_URL}/setdafultcard`, {
                'email': aruzyuser1.email, 'card': cardid,
            }).then((res) => {
                fetchData();
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    function DeleteCard(cardid) {
        if (window.confirm("Are you sure want to delete card.")) {
            document.getElementsByClassName('removechecked')[0].checked = false;
            axios.post(`${process.env.API_URL}/deleteCard`, {
                'email': aruzyuser1.email, 'card': cardid,
            }).then((res) => {
                fetchData();
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    function renderCard(card, defaultc, length) {
        return (<div key={card.id} className="row contactform pl-0">
            <div className="col-md-4">
                <label className="w-100 lineheight1">
                    {defaultc === card.id && <div className="float-right fontblue">
                        <input type="checkbox" className="removechecked" value={card.id} defaultChecked/> Set Default
                    </div>}
                    {defaultc != card.id && <div className="float-right textBlack">
                        <input type="checkbox" onClick={() => setDefault(card.id)} value={card.id}/> Set Default
                    </div>}
                </label>
                <div className="form-control readonly">{"********" + card.last4}</div>
            </div>
            <div className="col-md-3">
                <label className="fontblue">Exp. Month<span className="text-danger">*</span></label>
                <div className="form-control readonly">{getMonth(card.exp_month)}</div>
            </div>
            <div className="col-md-3">
                <label className="fontblue">Exp. Year<span className="text-danger">*</span></label>
                <div className="form-control readonly">{card.exp_year}</div>
            </div>
            {length > 1 && defaultc != card.id ? <div className="col-md-2 mb-3">
                <button className="btn btn-danger deleteCardBtn" onClick={() => DeleteCard(card.id)}>
                    <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="24px" height="24px">
                        <path
                            d="M 28 7 C 25.243 7 23 9.243 23 12 L 23 15 L 13 15 C 11.896 15 11 15.896 11 17 C 11 18.104 11.896 19 13 19 L 15.109375 19 L 16.792969 49.332031 C 16.970969 52.510031 19.600203 55 22.783203 55 L 41.216797 55 C 44.398797 55 47.029031 52.510031 47.207031 49.332031 L 48.890625 19 L 51 19 C 52.104 19 53 18.104 53 17 C 53 15.896 52.104 15 51 15 L 41 15 L 41 12 C 41 9.243 38.757 7 36 7 L 28 7 z M 28 11 L 36 11 C 36.552 11 37 11.449 37 12 L 37 15 L 27 15 L 27 12 C 27 11.449 27.448 11 28 11 z M 19.113281 19 L 44.886719 19 L 43.212891 49.109375 C 43.153891 50.169375 42.277797 51 41.216797 51 L 22.783203 51 C 21.723203 51 20.846109 50.170328 20.787109 49.111328 L 19.113281 19 z M 32 23.25 C 31.033 23.25 30.25 24.034 30.25 25 L 30.25 45 C 30.25 45.966 31.033 46.75 32 46.75 C 32.967 46.75 33.75 45.966 33.75 45 L 33.75 25 C 33.75 24.034 32.967 23.25 32 23.25 z M 24.642578 23.251953 C 23.677578 23.285953 22.922078 24.094547 22.955078 25.060547 L 23.652344 45.146484 C 23.685344 46.091484 24.462391 46.835938 25.400391 46.835938 C 25.421391 46.835938 25.441891 46.835938 25.462891 46.835938 C 26.427891 46.801938 27.183391 45.991391 27.150391 45.025391 L 26.453125 24.939453 C 26.419125 23.974453 25.606578 23.228953 24.642578 23.251953 z M 39.355469 23.251953 C 38.388469 23.224953 37.580875 23.974453 37.546875 24.939453 L 36.849609 45.025391 C 36.815609 45.991391 37.571109 46.801938 38.537109 46.835938 C 38.558109 46.836938 38.578609 46.835938 38.599609 46.835938 C 39.537609 46.835938 40.314656 46.091484 40.347656 45.146484 L 41.044922 25.060547 C 41.078922 24.094547 40.321469 23.285953 39.355469 23.251953 z"/>
                    </svg>
                </button>
            </div> : ""}
            <div className="col-md-12">
                <hr style={{border: '1px solid gray'}}/>
            </div>
        </div>);
    }

    function showcard() {
        setCardhide(true);
    }

    return (<div>
        <div className="mt-4 cardold">
            {carddetail.length > 0 && carddetail.map((card) => {
                return renderCard(card, defaultcard.default_source, carddetail.length);
            })}
        </div>
        <button className="slide_from_left changecard showcard col-md-8" onClick={() => showcard()} type="button">
                <span className="btnicon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40.809" height="40.876" viewBox="0 0 50.809 43.876">
                    <defs>
                        <filter id="Path_17" x="0" y="0" width="50.809" height="43.876" filterUnits="userSpaceOnUse">
                            <feOffset dy="3" input="SourceAlpha"/>
                            <feGaussianBlur stdDeviation="3" result="blur"/>
                            <feFlood floodOpacity="0.161"/>
                            <feComposite operator="in" in2="blur"/>
                            <feComposite in="SourceGraphic"/>
                        </filter>
                    </defs>
                    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_17)">
                    <path id="Path_17-2" data-name="Path 17"
                          d="M570,82.707H544.595a3.705,3.705,0,0,0-3.7,3.7v18.474a3.706,3.706,0,0,0,3.7,3.7H570a3.706,3.706,0,0,0,3.7-3.7V86.408a3.706,3.706,0,0,0-3.7-3.7m-2.971,20.25h-5.712a1.733,1.733,0,0,1,0-3.465h5.712a1.733,1.733,0,1,1,0,3.465m1.733-8.845a1.239,1.239,0,0,1-1.239,1.238h-20.45a1.238,1.238,0,0,1-1.238-1.238V89.54a1.239,1.239,0,0,1,1.238-1.238h20.45a1.24,1.24,0,0,1,1.239,1.238Z"
                          transform="translate(-531.89 -76.71)"
                          fill="#fff"/></g>
                    </svg>
                </span>
            <span className="imagectabtnlable">Change Card</span></button>
        {cardhide === true && <div className="col-md-12 mt-3">
            <Elements stripe={stripePromise}><AddCard/></Elements>
        </div>}
    </div>)
};

export default AttachedCard;