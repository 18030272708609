import React from "react"
import {navigate} from "gatsby"
import {isLoggedIn, loginPath} from "../services/auth"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const PrivateRoute = (props) => {
    if (!isLoggedIn() && props.location !== loginPath() && isBrowser) {
        navigate(loginPath())
        return null
    }
    return (<>{props.children}</>)
}

export default PrivateRoute