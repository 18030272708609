import * as React from "react"
import Header from "../partials/header"
import Footer from "../partials/footer"
import PrivateRoute from "../components/privateRoute"
import {logout} from "../services/auth";
import {getUserSubscription} from "../services/auth";
import {navigate} from "gatsby";
import axios from 'axios';
import {useEffect, useState} from 'react';
import 'react-tabs/style/react-tabs.css';
import AttachedCard from "../components/AttachedCard"
import original from "../images/default-user.svg"
import home from "../images/newimage/home.png"
import usericon from "../images/newimage/user.png"
import forward from "../images/newimage/forward.png"
import uploadIcon from "../images/newimage/camera.png";
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const AccountPage = () => {
    const isBrowser = typeof window !== "undefined";
    const aruzyuser = isBrowser ? localStorage.getItem('aruzyuser') : {};
    let aruzyuser1 = isBrowser && window.localStorage.getItem('aruzyuser') !== null ? JSON.parse(aruzyuser) : {};
    useEffect(() => {
        async function renderData() {
            await getUserSubscription();
            const isBrowser = typeof window !== "undefined";
            const aruzysubscription = isBrowser ? localStorage.getItem('aruzysubscription') : {};
            let aruzysubscriptionRes = isBrowser && window.localStorage.getItem('aruzysubscription') !== null ? JSON.parse(aruzysubscription) : {};
            if (aruzysubscriptionRes.subscription) {
                if (getdatediffernce(aruzysubscriptionRes.subscription.end) > 0 && (aruzysubscriptionRes.subscription.status === "Active" || aruzysubscriptionRes.subscription.status === "Cancelled" || aruzysubscriptionRes.subscription.status === "Trialing")) {
                    setSubscribe(aruzysubscriptionRes.subscription)
                }
                setPackage(aruzysubscriptionRes.subscription.package)
            }
        }

        renderData()
    }, [])

    const [formState, setFormState] = useState({
        fname: aruzyuser1.fname,
        lname: aruzyuser1.lname,
        address1: aruzyuser1.address1,
        address2: aruzyuser1.address2,
        city: aruzyuser1.city,
        county: aruzyuser1.county,
        postcode: aruzyuser1.postcode,
        mobile: aruzyuser1.mobile,
        password: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [nameState, setNameState] = useState({fname: aruzyuser1.fname, lname: aruzyuser1.lname,});
    const [subscribe, setSubscribe] = useState(false)
    const [subscribebutton, setSubscribebutton] = useState(true)
    const [getpackage, setPackage] = useState(false)
    const [resSuccess, setresSuccess] = useState(false)
    const [resError, setresError] = useState(false)
    const [passwordError, setpasswordError] = useState(false)
    const [respasswordSuccess, setrespasswordSuccess] = useState(false)
    const [profileImage, setProfileImage] = useState(aruzyuser1.image != null ? `${process.env.API_URL}` + aruzyuser1.image.url : original)

    function UpdateCustomer(formState) {
        try {
            axios.post(`${process.env.API_URL}/updatecustomer`, {
                'fname': formState.fname,
                'lname': formState.lname,
                'address1': formState.address1,
                'address2': formState.address2,
                'mobile': formState.mobile,
                'postcode': formState.postcode,
                'city': formState.city,
                'county': formState.county,
                'id': aruzyuser1.id,
            }).then((res) => {
                setresSuccess(true);
                const user = {
                    fname: res.data.data.fname,
                    lname: res.data.data.lname,
                    email: res.data.data.email,
                    address1: res.data.data.address1,
                    address2: res.data.data.address2,
                    mobile: res.data.data.mobile,
                    postcode: res.data.data.postcode,
                    city: res.data.data.city,
                    county: res.data.data.county,
                    id: res.data.data.id,
                    jwt: aruzyuser1.jwt,
                    image: aruzyuser1.image,
                };
                window.localStorage.setItem("aruzyuser", JSON.stringify(user));
                setNameState({
                    fname: res.data.data.fname,
                    lname: res.data.data.lname,
                });

            }).catch((error) => {
                setresError("Something went wrong try again")
            });

        } catch (e) {
            console.log(e);
        }
    }

    function ResetPassword(formState) {
        if (formState.newPassword === formState.confirmPassword) {
            try {
                axios.post(`${process.env.API_URL}/resetpassword`, {
                    'identifier': aruzyuser1.email,
                    'password': formState.password,
                    'newPassword': formState.newPassword,
                    'confirmPassword': formState.confirmPassword,
                    'id': aruzyuser1.id,
                })
                    .then((res) => {
                        setTimeout(() => {
                            setrespasswordSuccess(false)
                            setpasswordError(false)
                        }, 2000)
                        if (res.data.statusCode === 400) {
                            setrespasswordSuccess(false)
                            setpasswordError(res.data.message)
                        } else {
                            setpasswordError(false);
                            const user = {
                                fname: res.data.user.fname,
                                lname: res.data.user.lname,
                                email: res.data.user.email,
                                id: res.data.user.id,
                                jwt: res.data.jwt,
                            };
                            window.localStorage.setItem("aruzyuser", JSON.stringify(user));
                            setpasswordError(false);
                            setFormState({
                                password: '',
                                newPassword: '',
                                confirmPassword: '',
                            });
                            setrespasswordSuccess("Done")
                        }
                    })
                    .catch((error) => {
                        setpasswordError(true)
                    });
            } catch (e) {
                console.log(e);
            }
        } else {
            setpasswordError("Password does not match")
        }

    }

    const getdatediffernce = (end) => {
        const date1 = new Date(end);
        const date2 = new Date();
        var Difference_In_Time = date1.getTime() - date2.getTime();
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        return parseInt(Difference_In_Days) > 0 ? parseInt(Difference_In_Days) : 0;
    }
    const unsubscribe = () => {
        if (window.confirm("Are you sure you want to unsubscribe?")) {
            // get current logged in user
            const user = JSON.parse(localStorage.getItem('aruzyuser'));
            // call unsubscribe function
            axios.post(`${process.env.API_URL}/unsubscribe`, {
                'email': user.email,
                'subscribeid': subscribe.id,
            }).then((res) => {
                    if (res.data.error === false) {
                        alert("You have been unsubscribed successfully");
                        if (!res.data.afterduecancel) {
                            aruzyuser1.afterduecancel = true
                        }
                        setSubscribebutton(false);
                        window.localStorage.setItem("aruzyuser", JSON.stringify(aruzyuser1));
                    } else {
                        alert("Something went wrong try again later");
                    }
                }
            ).catch((error) => {
                alert("Something went wrong try again later");
            });
        }
    }

    const cancelSubscription = () => {
        const user = JSON.parse(localStorage.getItem('aruzyuser'));
        axios.post(`${process.env.API_URL}/testpackage`, {
            'id': user.id,
        }).then((res) => {
                console.log(res.data.data);
            let d = new Date(res.data.data.end);
                console.log(Math.floor(d.valueOf() / 1000));
            }
        ).catch((error) => {
            alert("Something went wrong try again later");
        });
    }

    let inputFile = '';
    const uploadClick = e => {
        e.preventDefault();
        inputFile.click();
        return false;
    }
    const uploadProfileImg = async e => {
        let formData = new FormData();
        formData.append('files', e.files[0]);
        axios.post(`${process.env.API_URL}/upload`, formData).then((res) => {
            axios.post(`${process.env.API_URL}/changeuserprofileimage`, {image: res.data[0].id, id: aruzyuser1.id}).then((response) => {
                if (response.data.error === false) {
                    setProfileImage(`${process.env.API_URL}` + response.data.image.url);
                    const user = {
                        fname: aruzyuser1.fname,
                        lname: aruzyuser1.lname,
                        email: aruzyuser1.email,
                        address1: aruzyuser1.address1,
                        address2: aruzyuser1.address2,
                        mobile: aruzyuser1.mobile,
                        postcode: aruzyuser1.postcode,
                        city: aruzyuser1.city,
                        county: aruzyuser1.county,
                        id: aruzyuser1.id,
                        jwt: aruzyuser1.jwt,
                        image: response.data.image,
                    };
                    window.localStorage.setItem("aruzyuser", JSON.stringify(user));
                    alert(response.data.msg);
                }
            }).catch((error) => {
                console.log('User Image Update Error', error);
            })
        }).catch((error) => {
            console.log('Image Upload Error', error);
        });
    }
    return (
        <PrivateRoute location="/account" component={AccountPage}>
            <main>
                <Header/>
                <AruzySeo title={"My Account"}/>
                <div className="App topattachbgsection">
                    <div className="container  changeheading pt-3 pb-5">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="text-center">
                                    <HeaderTitle title={'MY ACCOUNT'}/>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-xs-12 col-md-3 ">
                                        <div className="sidebaraccount">
                                            <div className="imagesec">
                                                <div className={'profileImgSec'}>
                                                    <img src={profileImage} loading={"eager"} key={(new Date()).getTime()} id={'profileImgView'} alt="Profile Image"/>
                                                    <span className="helloText">Hello!</span>
                                                    <span className={'uploadImg'} onClick={uploadClick}><img src={uploadIcon} alt={'Upload Icon'}/></span>
                                                    <input type="file" ref={i => inputFile = i} id={'profileImgInput'} onChange={e => uploadProfileImg(e.target)} accept={'image/*'} className={'d-none'}/>
                                                </div>
                                                <span className="acountname  font-600">{nameState.fname} {nameState.lname}</span><br/>
                                                <button onClick={event => {
                                                    event.preventDefault();
                                                    logout(() => navigate(`/login`))
                                                }} className="logoutbtn pinkcolor font-600">Log Out
                                                </button>
                                            </div>
                                            <div className="packageinfo mt-3">
                                                {subscribe &&
                                                    <div className="blogtag">
                                                        <img className="brandimage" alt="" src={getpackage.image != null ? `${process.env.API_URL}` + getpackage.image.url : 'https://via.placeholder.com/350x200.png?text=' + getpackage.name}/>
                                                        <div className="card-body text-center">
                                                            <h3 className="retailer text-center">My Subscription</h3>
                                                            <h6 className="pricetag">
                                                                {'£' + getpackage.price + ' / ' + (getpackage.limit > 1 ? getpackage.limit + ' ' + getpackage.interval + 's' : 'a' + ' ' + getpackage.interval)}
                                                            </h6>
                                                            <progress value={(30 - getdatediffernce(subscribe.end)) * 3.3} max="100" min="0"/>
                                                            <p style={{color: '#fc3571'}} className="mb-0">Renews
                                                                in {getdatediffernce(subscribe.end) > 0 ? getdatediffernce(subscribe.end) : 0} days</p>
                                                        </div>
                                                    </div>}
                                                {/*{(aruzyuser1.afterduecancel==false || aruzyuser1.afterduecancel==null) && subscribebutton  &&*/}
                                                {/*    <button className="btn btn-primary"*/}
                                                {/*            onClick={event => {*/}
                                                {/*                event.preventDefault();*/}
                                                {/*                unsubscribe()*/}
                                                {/*            }}>UnSubscribe*/}
                                                {/*    </button>*/}
                                                {/*}*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-9 ">
                                        <div className="detaiPageaccount">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className='myaccountsecion'>
                                                        <div className="cardmyaccount">
                                                            <img src={usericon} alt=""/>
                                                            <div className={'cardTitle'}>
                                                                <h3>My Account</h3><p>Edit your name or change your password</p>
                                                            </div>
                                                            <img src={forward} alt=""/>
                                                        </div>
                                                        <form className='contactform mt-4  pl-0' onSubmit={(e) => {
                                                            e.preventDefault();
                                                            UpdateCustomer(formState);
                                                        }}>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label>First Name</label>
                                                                    <input name="fname" className="form-control" value={formState.fname}
                                                                           onChange={(e) => setFormState({...formState, fname: e.target.value})} type="text"/>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <label>Last Name</label>
                                                                    <input name="lname" className="form-control" value={formState.lname}
                                                                           onChange={(e) => setFormState({...formState, lname: e.target.value})} type="text"/>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <label>Mobile</label>
                                                                    <input name="mobile"
                                                                           className="form-control noArrow"
                                                                           value={formState.mobile}
                                                                           onChange={(e) => setFormState({
                                                                               ...formState,
                                                                               mobile: e.target.value
                                                                           })} type="number"/>
                                                                </div>
                                                                <div className="col-md-12   ">
                                                                    <label>Address 1</label>
                                                                    <input name="address1"
                                                                           className="form-control"
                                                                           value={formState.address1}
                                                                           onChange={(e) => setFormState({
                                                                               ...formState,
                                                                               address1: e.target.value
                                                                           })} type="text"/>
                                                                </div>
                                                                <div className="col-md-12   ">
                                                                    <label>Address 2</label>
                                                                    <input name="address2"
                                                                           className="form-control"
                                                                           value={formState.address2}
                                                                           onChange={(e) => setFormState({
                                                                               ...formState,
                                                                               address2: e.target.value
                                                                           })} type="text"/>
                                                                </div>
                                                                <div className="col-md-12   ">
                                                                    <label>City</label>
                                                                    <input name="city"
                                                                           className="form-control"
                                                                           value={formState.city}
                                                                           onChange={(e) => setFormState({
                                                                               ...formState,
                                                                               city: e.target.value
                                                                           })} type="text"/>
                                                                </div>
                                                                <div className="col-md-12   ">
                                                                    <label>County</label>
                                                                    <input name="county"
                                                                           className="form-control"
                                                                           value={formState.county}
                                                                           onChange={(e) => setFormState({
                                                                               ...formState,
                                                                               county: e.target.value
                                                                           })} type="text"/>
                                                                </div>
                                                                <div className="col-md-12   ">
                                                                    <label>Postcode</label>
                                                                    <input name="postcode"
                                                                           className="form-control"
                                                                           value={formState.postcode}
                                                                           onChange={(e) => setFormState({
                                                                               ...formState,
                                                                               postcode: e.target.value
                                                                           })} type="text"/>
                                                                </div>
                                                            </div>

                                                            {resSuccess &&
                                                                <div
                                                                    className="alert alert-success mt-3">User
                                                                    Updated Successfully</div>}
                                                            {resError &&
                                                                <div
                                                                    className="alert alert-danger mt-3">Something
                                                                    went wrong try again</div>}

                                                            <div className="col-md-12 text-center">
                                                                <button className="slide_from_left submitformbtn col-md-6"
                                                                        type="submit"><span className="btnicon"><svg xmlns="http://www.w3.org/2000/svg" width="20.381" height="20.379"
                                                                                                                     viewBox="0 0 31.381 31.379"><path id="Path_370" data-name="Path 370"
                                                                                                                                                       d="M647.7,399.482l-22.405-6-.039-.012c-2.395-.644-3.713.045-4.385.725-.691.68-1.38,2-.737,4.394l.012.041v0l6,22.4a4.58,4.58,0,0,0,2.981,3.454,3.08,3.08,0,0,0,.791.1,3.394,3.394,0,0,0,2.394-1.058l5.23-5.233,6.211.1h.021a1.285,1.285,0,0,0,.9-.372l.005,0c.023-.026.033-.059.055-.085a1.225,1.225,0,0,0,.217-.327,1.249,1.249,0,0,0,.048-.22,1.211,1.211,0,0,0,.056-.255c0-.008,0-.014,0-.02a.159.159,0,0,0,0-.021l-.093-6.211,5.232-5.233a3.253,3.253,0,0,0,.956-3.179,4.581,4.581,0,0,0-3.455-2.985m-25.016-3.475c.2-.2.75-.311,1.663-.11l18.056,15.066.072,4.845-4.844-.074-15.068-18.056c-.2-.913-.09-1.463.121-1.671"
                                                                                                                                                       transform="translate(-619.881 -393.21)"
                                                                                                                                                       fill="#fff"/></svg></span>
                                                                    <span className="imagectabtnlable">Update</span></button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <form className="contactform mt-3 pl-0" onSubmit={(e) => {
                                                        e.preventDefault();
                                                        ResetPassword(formState);
                                                    }}>
                                                        <div className="row">
                                                            <div className="col-md-12   ">
                                                                <label>Old Password <span
                                                                    className="text-danger">*</span></label>
                                                                <input className="form-control"
                                                                       name="password"
                                                                       onChange={(e) =>
                                                                           setFormState({
                                                                               ...formState,
                                                                               password: e.target.value
                                                                           })
                                                                       } type="password"/>

                                                            </div>
                                                            <div className="col-md-12">
                                                                <label>New Password</label>
                                                                <input name="newPassword"
                                                                       className="form-control  "
                                                                       onChange={(e) =>
                                                                           setFormState({
                                                                               ...formState,
                                                                               newPassword: e.target.value
                                                                           })
                                                                       } type="password"/>
                                                            </div>
                                                            <div className="col-md-12  mb-3">
                                                                <label>Confirm Password<span
                                                                    className="text-danger">*</span></label>
                                                                <input name="confirmPassword"
                                                                       className="form-control"
                                                                       onChange={(e) =>
                                                                           setFormState({
                                                                               ...formState,
                                                                               confirmPassword: e.target.value
                                                                           })
                                                                       } type="password"/>
                                                            </div>
                                                        </div>
                                                        {respasswordSuccess &&
                                                            <div
                                                                className="alert alert-success mt-3">Password
                                                                Changed Successfully</div>}

                                                        {passwordError &&
                                                            <div
                                                                className="alert alert-danger mt-3">{passwordError}</div>}
                                                        <div className="col-md-12 text-center">
                                                            <div className="col-md-12 text-center">
                                                                <button className="slide_from_left submitformbtn col-md-6"
                                                                        type="submit"><span className="btnicon"><svg xmlns="http://www.w3.org/2000/svg" width="20.381" height="20.379"
                                                                                                                     viewBox="0 0 31.381 31.379"><path id="Path_370" data-name="Path 370"
                                                                                                                                                       d="M647.7,399.482l-22.405-6-.039-.012c-2.395-.644-3.713.045-4.385.725-.691.68-1.38,2-.737,4.394l.012.041v0l6,22.4a4.58,4.58,0,0,0,2.981,3.454,3.08,3.08,0,0,0,.791.1,3.394,3.394,0,0,0,2.394-1.058l5.23-5.233,6.211.1h.021a1.285,1.285,0,0,0,.9-.372l.005,0c.023-.026.033-.059.055-.085a1.225,1.225,0,0,0,.217-.327,1.249,1.249,0,0,0,.048-.22,1.211,1.211,0,0,0,.056-.255c0-.008,0-.014,0-.02a.159.159,0,0,0,0-.021l-.093-6.211,5.232-5.233a3.253,3.253,0,0,0,.956-3.179,4.581,4.581,0,0,0-3.455-2.985m-25.016-3.475c.2-.2.75-.311,1.663-.11l18.056,15.066.072,4.845-4.844-.074-15.068-18.056c-.2-.913-.09-1.463.121-1.671"
                                                                                                                                                       transform="translate(-619.881 -393.21)"
                                                                                                                                                       fill="#fff"/></svg></span>
                                                                    <span className="imagectabtnlable">Update</span></button>

                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='myaccountsecion'>
                                                        <div className="cardmyaccount">
                                                            <img src={home} alt=""/>
                                                            <div className={'cardTitle'}>
                                                                <h3>Billing Address</h3><p>Setup or change you billing address</p>
                                                            </div>
                                                            <img src={forward} alt=""/>
                                                        </div>
                                                        <AttachedCard/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </main>
        </PrivateRoute>
    )
}

export default AccountPage