import React, {useState} from 'react';
import {ElementsConsumer, CardElement} from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import axios from "axios/index";
import {navigate} from "gatsby";
const isBrowser = typeof window !== "undefined";
const loggedIn = isBrowser ? (localStorage.getItem('aruzyuser') !== null) : false;
const aruzyuser = isBrowser ? localStorage.getItem('aruzyuser') : {};
const aruzyuser1 = isBrowser && window.localStorage.getItem('aruzyuser')!==null ? JSON.parse(aruzyuser):{};
 

class AddCard extends React.Component {

    handleUpdate = event => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    state = {
        packages: [],
        id: '',
        resSuccess: '',
        resError: '',
        success: '',
        carderror: '',
        errorspinner: ''
    }

    handleSubmit = async event => {
        event.preventDefault();
         this.setState({
            errorspinner: 1
        });
        const {stripe, elements} = this.props;
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const result = await stripe.createToken(card);
        if (result.error) {
            this.setState({
                carderror: result.error.message
            });
            this.setState({
                errorspinner: ''
            });
        } else {
            this.setState({
                carderror: ''
            });
            var err = 0;
            try {
                if (err === 0) {
                     axios.post(`${process.env.API_URL}/addcard`, {
                        'email': aruzyuser1.email,
                        'token': result.token.id,
                    }).then((res) => {
                        if (res.data.error === true) {
                            this.setState({
                                    errorspinner: ''
                                })
                                this.setState({
                                resError: res.data.msg
                            });
                        } else {
                            this.setState({
                                    resError: ""
                                });
                                this.setState({
                                    errorspinner: ''
                                });
                                 this.setState({
                                    resSuccess: res.data.msg
                            });
                            navigate('/account')
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    this.setState({
                        errorspinner: ''
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    render() {
         return (
            <div>
                <form onSubmit={this.handleSubmit}>
                     <h3>Add Card</h3>
                <CardSection/>
                    {this.state.carderror && <div className="  card-error">{this.state.carderror}</div>}
                    <button className="slide_from_left showcard changecard mt-3  col-md-8"
                            type="submit"><span className="btnicon"><svg xmlns="http://www.w3.org/2000/svg" width="40.809" height="40.876" viewBox="0 0 50.809 43.876"><defs><filter id="Path_17" x="0" y="0" width="50.809" height="43.876" filterUnits="userSpaceOnUse"><feOffset dy="3" input="SourceAlpha"/><feGaussianBlur stdDeviation="3" result="blur"/><feFlood floodOpacity="0.161"/><feComposite operator="in" in2="blur"/><feComposite in="SourceGraphic"/></filter></defs><g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_17)"><path id="Path_17-2" data-name="Path 17" d="M570,82.707H544.595a3.705,3.705,0,0,0-3.7,3.7v18.474a3.706,3.706,0,0,0,3.7,3.7H570a3.706,3.706,0,0,0,3.7-3.7V86.408a3.706,3.706,0,0,0-3.7-3.7m-2.971,20.25h-5.712a1.733,1.733,0,0,1,0-3.465h5.712a1.733,1.733,0,1,1,0,3.465m1.733-8.845a1.239,1.239,0,0,1-1.239,1.238h-20.45a1.238,1.238,0,0,1-1.238-1.238V89.54a1.239,1.239,0,0,1,1.238-1.238h20.45a1.24,1.24,0,0,1,1.239,1.238Z" transform="translate(-531.89 -76.71)" fill="#fff"/></g></svg></span>
                        <span className="imagectabtnlable">Add Card &ensp;&ensp;
                            {this.state.errorspinner !== "" &&
                                <div className="spinner-border spinner-bordersignup" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>}
                    </span></button>
                </form>
                <div className="clearfix"></div>
                {this.state.resError !== "" && <div className="alert alert-danger mt-2">{this.state.resError}</div>}
                {this.state.resSuccess !== "" && <div className="alert alert-success mt-2">{this.state.resSuccess}</div>}
            </div>
        );
    }
}

export default function InjectedAddCard() {
    return (
        <ElementsConsumer>
            {({stripe, elements}) => (
                <AddCard stripe={stripe} elements={elements}/>
            )}
        </ElementsConsumer>
    );
}